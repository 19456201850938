import Vue from 'vue';
import Vuex, { Store } from 'vuex';

import RootState from '@/store/root/state';
import getters from '@/store/root/getters';
import mutations from '@/store/root/mutations';
import actions from '@/store/root/actions';
import AuthModule from "@/store/modules/auth"


Vue.use(Vuex);

const plugins: any[] = [];

/**
 * Vuex store instance, initialized with required root store,
 * modules and plugins.
 */
export const store: Store<any> = new Store({
    actions,
    getters,
    modules: {
        [AuthModule.vuexName]: new AuthModule(),        
    },
    mutations,
    plugins,
    state: new RootState(),
});

export default store;
