import actions from '@/store/modules/auth/actions';
import getters from '@/store/modules/auth/getters';
import mutations from '@/store/modules/auth/mutations';
import AuthState from '@/store/modules/auth/state';
import VuexModule from '@/store/VuexModule';


/**
 * User Vuex store module with its own state, getters, actions and mutations.
 *
 * @extends VuexModule<UserState>
 */
export default class Auth extends VuexModule<AuthState> {

    /**
     * Name of the module in Vuex store.
     */
    public static readonly vuexName: string = 'auth';

    /**
     * Creates user Vuex module, based on predefined class properties.
     */
    public constructor() {
        super();
        this.getters = getters;
        this.actions = actions;
        this.mutations = mutations;
        this.state = new AuthState();
    }
}
