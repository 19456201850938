import { ActionTree, ActionContext } from 'vuex';
import AuthState from './state';
import RootState from '@/store/root/state';
import Config from '@/config/global';

import { SET_AUTHORIZED_USER } from '@/store/modules/auth/mutations';
import LoginModel from '@/models/LoginModel';



export const SIGN_IN: string = 'signIn';

export async function signIn(
    store: ActionContext<AuthState, RootState>, loginModel : LoginModel
) {

    localStorage.setItem(Config.USER_LOCAL_STORAGE, loginModel.token);

    store.commit(SET_AUTHORIZED_USER, loginModel);    
}

export default {
    signIn
} as ActionTree<AuthState, RootState>
