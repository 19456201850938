
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";

@Component
export default class NavigatorDrawer extends Vue {
  @Prop() readonly drawer: boolean;
  private modelNav: boolean = false;
  private indexMenu: object = {};
  private items: any = [];

  created() {
    const listRoutes = this.$router.options.routes || [];
    for (const iterator of listRoutes) {
      if (iterator.meta && iterator.meta.menu) {
        this.items.push({
          name: iterator.meta.title,
          path: iterator.path,
        });
      }
    }
  }

  @Watch("modelNav")
  onModelNavChange(value: boolean, oldValue: boolean) {
    if (value == false) {
      this.$emit("close-menu");
    }
  }

  @Watch("drawer")
  onDrawerChange(value: boolean, oldValue: boolean) {
    this.modelNav = value;
  }

  @Watch("indexMenu")
  onindexMenuChange(value: boolean, oldValue: boolean) {
    this.modelNav = false;
    this.$emit("close-menu");
  }
}
