/**
 * Root state of vuex store.
 */
export default class RootState {

    /**
     * Application locale.
     */
    public locale: string;

    public notificationText: string;
    public notificationControl: number;
    public loading: boolean;

    /**
     * Creates initial root store state.
     */
    constructor() {
        this.locale = 'br';
        this.notificationText = "";
        this.notificationControl = 0;
        this.loading = false;
    }
}
