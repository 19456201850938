export default class AppRoutes {
    public static getRoutes = () => {
        return [
            {
                path: '*',
                meta: {
                    public: true,
                    title: ''
                },
                redirect: {
                    path: '/404'
                }
            },
            {
                path: '/404',
                meta: {
                    public: true,
                    title: 'Pagina não encontrada'
                },
                name: 'NotFound',
                component: () => import(
                    /* webpackChunkName: "routes" */
                    /* webpackMode: "lazy" */
                    `@/views/404/404.vue`

                )
            },
            {
                path: '/',
                name: 'Home',
                meta: {
                    menu: true,
                    title: 'Home'
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/home/Home.vue')
            },
            {
                path: '/anuncios',
                name: 'Anúncios',
                meta: {
                    menu: true,
                    title: 'Anúncios',
                    descricao: 'Lista dos anúncios cadastrados no sistema'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "about" */ '../views/anuncios/Anuncios.vue')
            },
            {
                path: '/cad-anuncios',
                name: 'CadAnuncios',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "about" */ '../views/anuncios/CadAnuncios.vue'),
                // props:(route:any) => ({ data: route.params })    
                props: (route: any) => {
                    //Passando multiplos valores para       
                    return ({
                        pData: route.params.pData,
                        pCidades: route.params.cidades,
                        pCategorias: route.params.categorias
                    })
                }
            },
            {
                path: '/cad-anuncios/:id',
                name: 'CadAnuncios',
                component: () => import(/* webpackChunkName: "about" */ '../views/anuncios/CadAnuncios.vue'),                
            },
            {
                path: '/categorias',
                name: 'Categorias',
                meta: {
                    menu: true,
                    title: 'Categorias',
                    descricao: 'Lista das categorias do sistema'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "about" */ '../views/categorias/Categorias.vue')
            },
            {
                path: '/cad-categorias',
                name: 'CadCategorias',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "about" */ '../views/categorias/CadCategorias.vue'),
                props: (route: any) => ({ data: route.params })
            },
            {
                path: '/cidades',
                name: 'Cidades',
                meta: {
                    menu: true,
                    title: 'Cidades',
                    descricao: 'Lista das cidades do sistema'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "about" */ '../views/cidades/Cidades.vue')
            },
            {
                path: '/cad-cidades',
                name: 'CadCidades',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "about" */ '../views/cidades/CadCidades.vue'),
                props: (route: any) => ({ data: route.params })
            },
            {
                path: '/usuarios',
                name: 'Usuarios',
                meta: {
                    menu: true,
                    title: 'Usuários',
                    descricao: 'Lista das usuáros do sistema'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "about" */ '../views/usuarios/Usuarios.vue')
            },
            {
                path: '/cad-usuarios',
                name: 'CadUsuarios',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "about" */ '../views/usuarios/CadUsuarios.vue'),
                props: (route: any) => ({ data: route.params })
            },
            {
                path: '/login',
                name: 'Login',
                meta: {
                    public: true,
                    title: ''
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "about" */ '../views/login/Login.vue')
            },
            {
                path: '/logout',
                name: 'Sair',
                meta: {
                    menu: true,
                    title: 'Sair',
                    descricao: 'Sair do sistema'
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "about" */ '../views/logout/Logout.vue')
            },
            // {
            //   path: '/about',
            //   name: 'Sobre',
            //   // route level code-splitting
            //   // this generates a separate chunk (about.[hash].js) for this route
            //   // which is lazy-loaded when the route is visited.
            //   component: () => import(/* webpackChunkName: "about" */ '../views/about/About.vue')
            // },

        ];
    }
}