import { MutationTree } from 'vuex';
import RootState from './state';


/**
 * Name of setLocale mutation.
 */
export const SET_LOCALE: string = 'setLocale';
export const SHOW_SNACK_BAR: string = 'showSnackBar';
export const SHOW_LOADING: string = 'showLoading';

/**
 * Sets new locale to store.
 *
 * @param state     Root state of vuex store.
 * @param locale    New value of locale.
 */
export function setLocale(state: RootState, locale: string) {
    state.locale = locale;
}

export function showSnackBar(state: RootState, value: string) {
    state.notificationText = value;               
    state.notificationControl = new Date().getTime();
}

export function showLoading(state: RootState, value: boolean) {
    state.loading = value;                   
}

export default {
    setLocale,
    showSnackBar,
    showLoading
} as MutationTree<RootState>
