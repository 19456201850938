import { Component, Vue, Watch } from 'vue-property-decorator';
import NavigatorDrawer from '@/components/navigator-drawer/NavigatorDrawer.vue'
import { Getter } from 'vuex-class';
import { GET_LOADING, GET_NOTIFICATION_CONTROL, GET_NOTIFICATION_TEXT } from '@/store/root/getters';

@Component({
    components: {
        NavigatorDrawer
    }
})
export default class App extends Vue {

    @Getter(GET_NOTIFICATION_TEXT)
    public notificationText: string;
    @Getter(GET_NOTIFICATION_CONTROL)
    public notificationControl: number;
    @Getter(GET_LOADING)
    public getLoading: boolean;

    private drawer: boolean = false;
    private snackbar: boolean = false;
    //private loading: boolean = false;

    get getNotificationText() {

        if (this.notificationControl > 0 && this.notificationText && this.notificationText.length > 0) {
            this.snackbar = true;
            return this.notificationText;
        }

        return "";
    }

    get loading(){        
        return this.getLoading;
    }


    public closeMenu() {
        this.drawer = false;
    }

    public toggleMenu(): void {
        this.drawer = !this.drawer;
    }

    public homeClick(): void {
        if (this.$route.path !== "/") {
            this.$router.replace("/");
        }
    }

    
}
