import Vue from 'vue';
import VueRouter from 'vue-router';
import AppRoutes from './app-routes';
import Config from '../config/global';
import jwt from 'jwt-simple';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes:AppRoutes.getRoutes()
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => !record.meta.public)) {

    const token = localStorage.getItem(Config.USER_LOCAL_STORAGE);
    if (!token) {
      next({ path: '/login' })
      return;
    }

    const jwtResult = jwt.decode(token, "", true);
    if (!jwtResult) {
      next({ path: '/login' })
      return;
    }
    else if (jwtResult.exp < (Date.now() / 1000)) {
      next({ path: '/login' })
      return;
    }
    else {
      next()
    }
  } else {
    next()
  }

  next();
});

export default router;
