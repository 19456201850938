import { GetterTree } from 'vuex';
import RootState from './state';


/**
 * Name of locale getter.
 */
export const LOCALE: string = 'locale';
export const GET_NOTIFICATION_TEXT : string = 'notificationText';
export const GET_NOTIFICATION_CONTROL : string = 'notificationControl';
export const GET_LOADING : string = 'getLoading';

/**
 * Returns current value of locale property.
 *
 * @param state     Root vuex state.
 */
export function locale(state: RootState): string {
    return state.locale;
}

export function notificationText(state: RootState): string {    
    return state.notificationText ? state.notificationText : "";
}
export function notificationControl(state: RootState): number {    
    return state.notificationControl;
}
export function getLoading(state: RootState): boolean {       
    return state.loading;
}




export default {
    locale,
    getLoading,
    notificationControl,
    notificationText
} as GetterTree<RootState, RootState>
