import * as dotenv from "dotenv";
dotenv.config()

export default class Global {

    
    public static USER_LOCAL_STORAGE: string = "guia-do-bem-user-key"
    
    public static URL_API_V2: string = Global.getProcessValue("VUE_APP_URL_API_V2");
    
    public static getProcessValue(key:string):string {
        dotenv.config();
                        
        return process.env[key] != undefined
            ? process.env[key]!
            : "";
    }
}