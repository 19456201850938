import { ActionTree, ActionContext } from 'vuex';
import RootState from './state';
import { SHOW_LOADING, SHOW_SNACK_BAR } from '@/store/root/mutations';

export async function showSnackBar(
    store : ActionContext<RootState, RootState>, text:string
) {            
    store.commit(SHOW_SNACK_BAR, text);
}

export async function showLoading(
    store : ActionContext<RootState, RootState>, value:boolean
) {                
    store.commit(SHOW_LOADING, value);
}
export default {
    showSnackBar,
    showLoading
} as ActionTree<RootState, RootState>
