import LoginModel from '@/models/LoginModel';

export default class AuthState {

    public loginModel: LoginModel | null;

    /**
     * Token de autenticação
     */
    public token: string | null;

    /**
     * Creates initial user store state.
     */
    constructor() {
        this.token = null;
        this.loginModel = null;
    }
}
