import LoginModel from '@/models/LoginModel';
import { MutationTree } from 'vuex';
import AuthState from './state';


/**
 * Name of setAuthorizedUser mutation.
 */
export const SET_AUTHORIZED_USER: string = 'setAuthorizedUser';

/**
 * Sets new MyUser instance as authorizedUser in user state.
 * 
 * @param state AuthState
 * @param loginModel LoginModel.
 */
export function setAuthorizedUser(state: AuthState, loginModel: LoginModel) {
    state.loginModel = loginModel;
    state.token = loginModel.token;
}


export default {
    setAuthorizedUser
} as MutationTree<AuthState>
